.freeLanceExp {
  padding: 1% 3%;
  label {
    height: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;

    color: #3e4265;
  }
  .radioCheck {
    display: flex;
    .form-check {
      display: flex;
      align-items: center;

      input {
        margin-right: 8px;
        &:hover {
          cursor: pointer;
        }
      }
      label {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}
