.mycalender {
  height: 3.5rem;
  .react-date-picker__wrapper {
    border-radius: 10px;
    border: 1px solid #d4d7e5;
  }
  .react-date-picker__inputGroup {
    padding: 0px 56% 0 2%;
  }
  //   .react-date-picker__inputGroup__divider {
  //     display: none;
  //   }
  .react-date-picker__inputGroup__input {
    outline: none !important;
  }
  .react-calendar__month-view__days__day {
    border: none;
    background: white;
    padding: 1%;
  }
  .react-date-picker__calendar--open {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px,
      rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
    background-color: #fff;
  }
  .mmCalneder {
    .react-calendar__navigation {
      margin: 1% 0;
    }
    .react-calendar__month-view__weekdays {
      > div {
        padding: 0 1%;
      }
    }
  }
}
