.menu {
  display: flex;
  .menulist {
    border-right: 1px solid #eeeeee;
    width: 11%;
  }
  .maincontent {
    width: 89%;
    overflow-y: auto;
    padding: 0 4%;
  }
}
