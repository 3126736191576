.sidebar {
  .menuitem {
    border-bottom: 1px solid #dddddd;
    display: flex;
    height: 82px;
    .activediv {
      width: 6%;
      height: 100%;
    }
    .activediv.isactive {
      background: linear-gradient(178.16deg, #6366f1 1.55%, #9799ff 202.67%);
    }
    .nav-link {
      width: 100%;
      display: flex;
      flex-direction: column;
      .iconSize {
        font-size: 1.8rem;
      }
    }
    .nav-link.isactive {
      color: #6366f1;
      background: #f8f8f8;
      cursor: none;
      pointer-events: none;
    }
    .nav-link.inactive {
      cursor: pointer;
      pointer-events: auto;
    }
    .nav-link.disabled {
      cursor: none;
      pointer-events: none;
    }
  }
}
