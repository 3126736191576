.customcard {
  margin-top: 3%;
  width: 30%;
  min-width: 382px;
  min-height: 174px;
  background: #ffffff;
  border: 1px solid rgba(166, 166, 166, 0.32);
  box-shadow: 0px 26px 51px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  margin-right: 2%;
  &:hover {
    cursor: pointer;
    border: 2px solid #6366f1;
  }
  .workbag_icon {
    width: 40px;
    height: 40px;
  }

  .card-text {
    // height: 48px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #a6a6a6;
  }
}
.customcard.active {
  border: 2px solid #6366f1;
}

.highlight {
  stroke: #491ec4;
}
.employerCard {
  width: 86%;
  margin: 3% auto;
  border: none;

  .card-header {
    border: none;
    .subtitle {
      margin: 0;
      padding-left: 4%;
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      color: #131022;
    }
  }
  .card-body {
    padding: 1.5rem 25%;
    .bodyTemplate {
      .close-icon {
        display: inline-block;
        font-size: 2rem;
        color: #000;
        &:hover {
          cursor: pointer;
        }
      }
      .subtitle {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #131022;
        margin-bottom: 5%;
      }
    }
  }
  .calender-body {
    height: 428rem;
    padding: 4% !important;
    .bodyTemplate {
      height: 100%;

      .title {
        margin: 1% 42% 1% 1% !important;
      }
      .content {
        height: 100%;
      }
    }
  }
  .suitable-candidate-body {
    padding: 4% !important;
  }
}
.alert-custom-employer {
  width: 100%;
  height: 64px;
  background: linear-gradient(178.16deg, #f16363 1.55%, #ff97cf 202.67%);
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin: 0;
    color: rgba(255, 255, 255, 1);
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
  }
}
