.employerReview {
  box-shadow: 0px 26px 51px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: space-between;
  .description {
    color: rgba(19, 16, 34, 1);
    font-size: 28px;
    font-weight: 900;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
  }
}
.empReview {
  padding: 1.5rem 12% !important;
}
