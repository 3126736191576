.employercard {
  .employer-body {
    .card-description {
      .description {
        color: #585c7b;
        font-size: 16px;
        font-weight: 400;
        line-height: 22, 4px;
        margin: 0;
        padding-left: 3%;
      }
    }
  }
}
.button-shortlist {
  z-index: 2;
  position: relative;
  border-color: #6366f1;
  width: 127px;
  height: 50px;
  color: #6366f1 !important;
  &:hover {
    color: #fff !important;
  }
}
