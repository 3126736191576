.candidatecard {
  border: 1px solid rgba(111, 111, 111, 0.2) transparent transparent !important;
  &:hover {
    border: 1px solid #6366f1 !important;
    cursor: pointer;
  }
  .candidate-body {
    .card-description {
      .description {
        color: #585c7b;
        font-size: 16px;
        font-weight: 400;
        line-height: 22, 4px;
        margin: 0;
        padding-left: 3%;
      }
    }
  }
}
