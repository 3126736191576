.btn-primary:disabled {
  background: #a6a6a6 !important;
  border: none;
}
.uploadIcon_svg {
  display: block;
  text-indent: -9999px;
  width: 47px;
  height: 76px;
  background: url("../../../src/Assets/icons/upload.svg") no-repeat;
  &:hover {
    cursor: pointer;
  }
  // background-size: 100px 82px;
}

.workbag_svg {
  display: block;
  width: 47px;
  height: 76px;
  &:hover {
    cursor: pointer;
  }
  // background-size: 100px 82px;
}
.workbag_svg_green {
  background: url("../../../src/Assets/icons/workbag_Green.svg") no-repeat;
}

.workbag_svg_red {
  background: url("../../../src/Assets/icons/workbag_Red.svg") no-repeat;
}

.workbag_svg_orange {
  background: url("../../../src/Assets/icons/workbag_Orange.svg") no-repeat;
}
.dashboard_active {
  display: block;
  width: 34px;
  height: 42px;
  background: url("../../../src/Assets/icons/dashboard_active.svg") no-repeat;
}

.dashboard_black {
  display: block;
  width: 34px;
  height: 42px;
  background: url("../../../src/Assets/icons/dashboard_black.svg") no-repeat;
}
.user_wyg {
  display: block;
  height: 11rem;
  width: 11rem;
  background: url("../../../src/Assets//icons/user.svg") no-repeat;
}
.key_wyg {
  display: block;
  height: 11rem;
  width: 11rem;
  background: url("../../../src/Assets//icons/key.svg") no-repeat;
}
.video_wyg {
  display: block;
  height: 11rem;
  width: 11rem;
  background: url("../../../src/Assets//icons/video.svg") no-repeat;
}
.reviews {
  display: block;
  width: 128px;
  height: 128px;
  background: url("../../../src/Assets//icons/reviews.svg") no-repeat;
}
.isverified {
  display: block;
  width: 128px;
  height: 128px;
  background: url("../../../src/Assets//icons/isverified.svg") no-repeat;
}
.left-arrow {
  display: block;
  width: 2rem;
  margin-top: 1%;
  height: 2rem;
  background: url("../../../src/Assets//icons/left-arrow.svg") no-repeat;
}

.lock {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: url("../../../src/Assets//icons/lock.svg") no-repeat;
}
.note {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: url("../../../src/Assets//icons/note.svg") no-repeat;
}
.vector {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: url("../../../src/Assets//icons/Vector.svg") no-repeat;
}
.role {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: url("../../../src/Assets//icons/role.svg") no-repeat;
}
.star {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  background: url("../../../src/Assets//icons/star.svg") no-repeat;
}
.editSchdedue {
  display: block;
  width: 1rem;
  height: 1rem;
  margin-left: 3%;
  background: url("../../../src/Assets//icons/editSchdedue.svg") no-repeat;
}
.candidateProfilePic {
  display: block;
  width: 11rem;
  height: 11rem;
  margin-left: 3%;
  background: url("../../../src/Assets//icons/candidate_ProfilePic.svg") no-repeat;
}
.shorlistProfilePic {
  display: block;
  width: 100px;
  height: 100px;
  margin: 5px;
  background: url("../../../src/Assets//icons/candidate_ProfilePic.svg") no-repeat;
}
.backButton {
  display: block;
  width: 1rem;
  height: 2rem;
  margin-right: 3%;
  margin-top: 0.7%;
  background: url("../../../src/Assets//icons/back_Button.svg") no-repeat;
}
.rectangleProfilePic {
  display: block;
  width: 11rem;
  height: 11rem;
  background: url("../../../src/Assets//icons/rectangle_profile.svg") no-repeat;
}
.linkedIn {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 3%;
  background: url("../../../src/Assets//icons/linkedin-icon.svg") no-repeat;
}
.epicIcon {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 3%;
  background: url("../../../src/Assets//icons/epic-games.svg") no-repeat;
}
.artstation {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 3%;
  background: url("../../../src/Assets//icons/artstation.svg") no-repeat;
}
.workIcon {
  display: block;
  width: 1.6rem;
  height: 1.7rem;
  margin-right: 1%;
  background: url("../../../src/Assets//icons/work-alt-bag.svg") no-repeat;
}