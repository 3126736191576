.modal-backdrop.show {
  opacity: 0.75;
}
.unreal-modal {
  padding-right: 0 !important;
  z-index: 2052;
  .modal-tl {
    max-width: unset !important;
    height: 100% !important;
    margin: 0 !important;
    .modal-content {
      height: 100% !important;
    }
    .modal-footer,
    .modal-body {
      padding: 0 !important;
    }
  }
}
