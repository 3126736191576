.dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .dashboard-heading {
    width: 80%;
    margin: auto;
    margin-top: 5%;
    h1 {
      text-transform: capitalize;
      color: #141414;
      font-weight: 700;
      font-size: 40px;
    }
    label {
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      color: #585c7b;
    }
  }
  .dashboard-stages {
    position: relative;
    display: flex;
    margin: 5% 0;
    // width: 80%;
    justify-content: center;
    .stage-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 150%;
      color: #141414;
      margin: 7% 0;
    }
    .stage-description {
      margin-bottom: 7%;
      width: 261px;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #585c7b;
    }
    .stage {
      width: 261px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 13%;
      .btn {
        border-radius: 8px;
        border: 1px solid #6366f1;
        box-shadow: 0px 8px 18px -8px rgba(99, 102, 241, 0.9);
      }
      .btn-primary:disabled {
        background: #a6a6a6 !important;
        border: none;
      }
    }

    .stage-dashed-line {
      opacity: 0.5;
      border: 2px dashed #000000;
      width: 211.5px;
      position: absolute;
      top: 16%;
      left: 23%;
    }
    .arrow {
      position: absolute;
    }
    .arrow.leftArrow {
      top: 80%;
      left: 92%;
      height: 72px;
      z-index: 1;
    }
  }
  .dashboard-page-content {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 3% 0;
    label.title {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      display: flex;
      align-items: center;
      color: #131022;
      margin-top: 1%;
    }
    label.description {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #585c7b;
      margin-top: 1%;
      width: 60%;
    }
  }
  .wyg {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 80%;
    margin-bottom: 10%;
    h1 {
      text-align: center;
      font-weight: 900;
      font-size: 30.8847px;
      line-height: 43px;
      color: #131022;
      margin: 6% 0;
    }
    .wyg-body {
      display: flex;
      justify-content: space-around;
    }
    .wyg-body > div {
      width: 22%;
      display: flex;
      flex-direction: column;
      align-items: center;
      title {
        display: block;
        justify-items: center;
        text-align: justify;
        font-weight: 700;
        font-size: 15.816px;
        line-height: 24px;
        color: #131022;
        padding: 4% 0;
      }
      label {
        display: block;
        justify-items: center;
        text-align: justify;
        color: #585c7b;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
