.monetry {
  padding: 2% 0;
  box-sizing: border-box;
  .title {
    display: flex;
    align-items: center;
    label {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      height: 32px;
      color: #3e4265;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      padding-left: 2%;
    }
  }
  .earning {
    padding: 1% 3%;
    width: 36%;
    label {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      font-weight: 600;
      font-size: 16px;
      height: 32px;
      color: #3e4265;
      height: 32px;
      margin: 1% 0;
    }
    input[type="number"]::placeholder {
      /* Firefox, Chrome, Opera */
      text-align: right;
    }
    input::-webkit-inner-spin-button {
      display: none;
    }
  }
}
