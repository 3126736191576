.availability {
  padding: 2% 0;
  box-sizing: border-box;
  .title {
    display: flex;
    align-items: center;
    label {
      display: flex;
      align-items: center;
      text-transform: capitalize;
      height: 32px;
      color: #3e4265;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 32px;
      padding-left: 2%;
    }
  }
}
.availabilitylist {
  height: 14rem;
  margin: 0 3%;
}
