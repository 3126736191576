.experience {
  padding: 1% 3%;
  label {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    height: 32px;
    color: #3e4265;
    height: 32px;
    margin: 1% 0;
  }
  .dropdown {
    button {
      background-color: #ffffff;
      border: 1px solid #d4d7e5;
      color: #5f5f5f !important;
      &:focus {
        background-color: #ffffff;
        border: 1px solid #d4d7e5;
      }
    }
  }
}
