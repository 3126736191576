$lineheight: 42px;

.headerTemplate {
  label {
    background: linear-gradient(90deg, #6366f1 0%, #8b5cf6 50%, #d946ef 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 900;
    font-size: 32px;
    line-height: $lineheight;
  }
  i {
    font-size: 32px;
    color: #000;
    line-height: $lineheight;
    &:hover {
      cursor: pointer;
    }
  }
}

.bodyTemplate {
  input::-webkit-inner-spin-button {
    display: none;
  }
  .title {
    font-weight: 900;
    font-size: 28px;
    line-height: 36px;
    color: #131022;
    margin: 3% 0;
  }
}
