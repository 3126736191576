.completeprofile {
  padding: 4% 3%;
  .mainheadline {
    h1 {
      /* width: 760px; */
      height: 60px;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      line-height: 150%;
      text-transform: capitalize;
      color: #131022;
      margin: 0;
    }
    label {
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      line-height: 32px;
      width: 598px;
      height: 32px;
    }
  }
  .profilesection-1 {
    padding-top: 4%;
    padding-bottom: 2%;

    .profiletitle {
      padding: 0 2%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .label-1 {
        width: 199px;
        height: 30px;
        font-weight: 600;
        font-size: 20px;
        line-height: 150%;
        color: #141414;
      }
      .label-2 {
        width: 327px;
        padding: 0.5% 0;
        font-family: "Segoe UI";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #585c7b;
      }
    }
  }
  .upload {
    padding: 2% 0;
    position: relative;
    .uploadcontainer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36%;
      height: 158px;
      background: #ffffff;
      border: 1px solid #d4d7e5;
      border-radius: 8px;
      position: relative;
      .upload-file-input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        width: 100%;
        height: 100%;
        &:hover {
          cursor: pointer;
        }
      }
      .close {
        position: absolute;
        right: 2%;
        top: 2%;
        font-size: 2rem;
        z-index: 1;
        &:hover {
          cursor: pointer;
          color: #6366f1;
        }
      }
    }
    .label-1-upload {
      // height: 32px;
      padding: 0.5% 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 32px;
    }
    .label-2-upload {
      display: flex;
      align-items: center;
      // text-transform: uppercase;
      color: #aeaeae;
      // height: 32px;
      padding: 0.5% 0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
    }
    .arrow {
      position: absolute;
    }
    .collectionImage {
      position: absolute;
      left: 39%;
      top: 25%;
      .innerCollection {
        position: relative;
        width: 30rem;
        height: 30rem;
      }
    }
    .leftArrow {
      top: 75%;
      left: 32%;
      height: 72px;
      z-index: 1;
    }
    .rightArrow-1 {
      width: 473.56px;
      height: 514.74px;
      left: 50%;
    }
    .rightArrow-2 {
      width: 196.27px;
      height: 214.02px;
      left: 58%;
    }
    .rightArrow-3 {
      width: 196.27px;
      height: 214.02px;
      top: 28%;
      left: 110%;
    }
  }
  .skills {
    padding: 2% 0;
    .title {
      display: flex;
      align-items: center;
      label {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        height: 32px;
        color: #3e4265;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        padding-left: 2%;
      }
    }
  }
  .portfolia-link {
    padding: 2% 0;
    width: 36%;
  }
}
.dotSymbol {
  border-radius: 50%;
  width: 9px;
  height: 9px;
  background: #6366f1;
  box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12),
    0px 6px 13px rgba(0, 0, 0, 0.12);
}
.submitprofile {
  display: flex;
  justify-content: space-between;
  justify-items: baseline;
  margin: 0 29%;
  .btn {
    box-sizing: border-box;
    width: 268px;
    height: 47px;
    background: #6366f1;
    border: 1px solid #6366f1;
    border-radius: 8px;
    margin: 1% 2%;
  }
  .shadow {
    box-shadow: 0px 8px 18px -8px rgba(99, 102, 241, 0.9) !important;
  }
  .skip {
    color: #6366f1 !important;
    background: #eff2fc;
    border: 1px solid #eff2fc;
  }
}
