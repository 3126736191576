.shortlistcard {
  .shortlist-body {
    .card-description {
      .description {
        color: #585c7b;
        font-size: 16px;
        font-weight: 400;
        line-height: 22, 4px;
        margin: 0;
        padding-left: 3%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }
  }
}
.vertical-align {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-direction: row;
}
