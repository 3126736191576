.chip {
  background: #ffffff;
  display: inline-block;
  border: 1px solid #f8f8f8;
  box-shadow: 2px 4px 8px rgba(133, 133, 133, 0.09);
  border-radius: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  margin-right: 1%;
  margin-bottom: 1%;
  &:hover {
    cursor: pointer;
    // border: 1px solid #cececd;
  }
}
.chip.active {
  border: 1px solid #6366f1;
}
