.experience-range {
  padding: 1% 3%;
  label {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-weight: 600;
    font-size: 16px;
    height: 32px;
    color: #3e4265;
    height: 32px;
    margin: 1% 0;
  }
}
