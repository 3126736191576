.range-wrapper {
  position: relative;
  height: 5rem;
  .range-wrap {
    position: relative;
    margin: 0 auto 3rem;
    height: 80%;
    .range {
      width: 100%;
    }
    .bubble {
      background: white;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      color: #414141;
      position: absolute;
      left: 50%;
      bottom: 1%;
      width: 3rem;
      transform: translateX(-50%);
    }
    //   .bubble::after {
    //     content: "";
    //     position: absolute;
    //     width: 2px;
    //     height: 2px;
    //     background: #414141;
    //     top: -1px;
    //     left: 50%;
    //   }
  }

  #myinput {
    //   border: solid 1px #6366f1;
    border-radius: 8px;
    height: 2px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }
  input[type="range"]::-webkit-slider-thumb {
    display: flex;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #6366f1;
    box-shadow: 0px 0.5px 4px rgba(0, 0, 0, 0.12),
      0px 6px 13px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    -webkit-appearance: none;
  }
}
