.steps {
  width: 90px;
  height: 90px;
  background: #ffffff;
  box-shadow: 0px 4.4px 12px -1px rgba(19, 16, 34, 0.15),
    0px 2px 6.4px -1px rgba(19, 16, 34, 0.03);
  border-radius: 45px;
  label {
    color: #6366f1;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 51px;
    width: 18px;
    /* height: 36px; */
  }
}
