.candidate-card {
  width: 344px;
  height: 480px;
  background: #ffffff;
  border: 1px solid #f8f8f8;
  box-shadow: 0px 26px 51px rgba(0, 0, 0, 0.06);
  &:hover {
    // border: 1px solid #6366f1;
    cursor: pointer;
  }
  margin-bottom: 2%;
  img {
    width: 300px;
    height: 273.09px;
    margin: 6% auto;
    border-radius: 8px;
  }
  .card-body {
    padding: 1% 7%;
    .card-text-p {
      display: flex;
      color: #585c7b;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      margin: 0;
      margin-top: 4%;
      p {
        margin: 0;
        margin-left: 4%;
      }
    }
  }
}
.candidate-card.active {
  border: 1px solid #6366f1;
}
