.main-section-candidate {
  width: 96%;
  .cta {
    &:hover {
      cursor: pointer;
    }
  }
}
.candidate-card {
  .candidate-body {
    .card-description {
      .description {
        color: #585c7b;
        font-size: 12px;
        font-weight: 400;
        line-height: 22, 4px;
        margin: 0;
        padding-left: 3%;
      }
      .description-value {
        color: #424242;
        font-size: 16px;
        font-weight: 400;
        line-height: 22, 4px;
        margin: 0;
      }
    }
  }
}

.tools {
  color: #424242;
  padding: 10px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.tools-border {
  border-radius: 16px;
}
.heading-cotent {
  color: #131022;
  font-size: 16px;
  font-weight: 600;
  line-height: 22, 4px;
  margin: 0;
  .desc {
    display: flex;
    align-items: flex-start !important;
  }
}
.heading-data {
  color: #131022;
  font-size: 14px;
  font-weight: 400;
  line-height: 22, 4px;
  margin-right: 2px;
  margin-bottom: 2px;
}
.span-color {
  color: #aeaeae;
  margin-left: 10px;
}
.work-history-heading {
  color: #585c7b;
  font-weight: 400;
  font-size: 12px;
  line-height: 22, 4px;
}
.work-history-data {
  color: #424242;
  font-weight: 600;
  font-size: 14px;
  line-height: 22, 4px;
}
.vertical-line {
  background-color: #a6a6a6;
  height: 182px;
  width: 0.5px;
}
.shortlist-button {
  background-color: #6366f1;
  width: 208px;
  height: 50px;
  float: right;
}
